/** @format */

import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import InfoCard from "../components/InfoCard"
import HyperLinkText from "../components/HyperLinkText"

// eslint-disable-next-line
export const GetInvolvedPageTemplate = ({ title }) => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-desktop">
              <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
                {title}
              </h2>
              <p className="subtitle has-text-grey mb-5">
                Raleigh residents and businesses are flying the new flag,
                sporting flag gear, and spreading the word. Join the movement
                and help make the new flag official.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section mb-5">
        <div className="container mb-3">
          <div
            className="columns is-multiline is-centered"
            style={{ alignItems: "stretch" }}
          >
            <div className="column is-12 is-4-desktop">
              <InfoCard
                cardImage={"/img/IMG_7151.jpg"}
                cardTitle={"Fly a flag"}
                cardBtn={{ link: "/shop", text: "Buy a flag" }}
              >
                Fly the new flag at home or work. Tell your neighbors about the
                movement!
              </InfoCard>
            </div>
            <div className="column is-12 is-4-desktop">
              <InfoCard
                cardImage={"/img/get-involved-swag.png"}
                cardTitle={"Rock some swag"}
                cardBtn={{
                  link: "https://www.zazzle.com/collections/flag_swag-119311331102562265",
                  text: "Visit shop",
                }}
              >
                Check out our online store at Zazzle.
              </InfoCard>
            </div>
            <div className="column is-12 is-4-desktop">
              <InfoCard
                cardImage={"/img/get-involved-civic-pride.jpg"}
                cardTitle={"Share your civic pride"}
                cardBtn={{
                  link: "mailto:info@newraleighflag.com?subject=Gallery photo",
                  text: "E-mail photo",
                }}
              >
                If you have a flag, send a photo of it flying at your home or
                business! We’ll add it to the{" "}
                <HyperLinkText link={"/gallery"} text={"gallery"} /> here on our
                website.
              </InfoCard>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box mb-5">
            <div className="m-5 p-3 columns is-multiline is-vcentered">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4 is-centered">
                  <img
                    className="image"
                    style={{ objectFit: "cover", width: "75%" }}
                    src="/img/getinvolved-social.png"
                    alt="getinvolved-social"
                  />
                </div>
                <div className="column is-8">
                  <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
                    Spread the word​
                  </h2>
                  <p className="subtitle has-text-grey mt-4">
                    Engage with the campaign on your favorite social media
                    platform. Every share, like, tag, and retweet builds
                    awareness. Find social media links at the bottom of this
                    page.​ ​
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box mb-5">
            <div className="m-5 p-3 columns is-multiline is-vcentered">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4 is-centered">
                  <img
                    className="image"
                    style={{ objectFit: "cover", width: "75%" }}
                    src="/img/Noun_Project_people_icon.png"
                    alt="Noun_Project_people_icon"
                  />
                </div>
                <div className="column is-8">
                  <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
                    Join the effort
                  </h2>
                  <p className="subtitle has-text-grey mt-4">
                    Got great ideas?  Want to engage in a fun, unifying
                    campaign?  Join the fray and help with networking,
                    distribution, social media, and more!
                  </p>
                  <Link
                    to="mailto:info@newraleighflag.com?subject=New Raleigh Flag Campaign"
                    className="ml-auto button is-primary"
                  >
                    E-mail us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box mb-5">
            <div className="m-5 p-3 columns is-multiline is-vcentered">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4 is-centered">
                  <img
                    className="image"
                    style={{ objectFit: "cover", width: "75%" }}
                    src="/img/pantheonicon.png"
                    alt="pantheonicon"
                  />
                </div>
                <div className="column is-8">
                  <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
                    Advocate
                  </h2>
                  <p className="subtitle has-text-grey mt-4">
                    Contact your{" "}
                    <HyperLinkText
                      link={"https://raleighnc.gov/city-council"}
                      text={"Raleigh city council"}
                    />{" "}
                    member! Tell them you support the New Raleigh Flag
                    Initiative and ask them to adopt the new design as an
                    official flag of the city.
                  </p>
                  <a
                    href="mailto:citycouncilmembers@raleighnc.gov?subject=Raleigh Flag"
                    className="ml-auto button is-primary"
                  >
                    E-mail City Council​
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="box">
            <div className="m-5 p-3 columns is-multiline is-vcentered">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4 is-centered">
                  <img
                    className="image"
                    style={{ objectFit: "cover", width: "75%" }}
                    src="/img/painticon.png"
                    alt="painticon"
                  />
                </div>
                <div className="column is-8">
                  <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
                    Represent!
                  </h2>
                  <p className="subtitle has-text-grey mt-4">
                    Use our high-resolution flag graphics to create your own
                    flag-themed gear and content!
                  </p>
                  <Link to="/download" className="ml-auto button is-primary">
                    Visit Download Page​
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="box mb-5">
            <div className="m-5 p-3 columns is-multiline is-vcentered">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4 is-centered">
                  <img
                    className="image"
                    style={{ objectFit: "cover", width: "75%" }}
                    src="/img/painticon.png"
                    alt="painticon"
                  />
                </div>
                <div className="column is-8">
                  <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
                    Represent!
                  </h2>
                  <p className="subtitle has-text-grey mt-4">
                    Use our high-resolution flag graphics to create your own
                    flag-themed gear and content!
                  </p>
                  <Link to="/download" className="ml-auto button is-primary">
                    Visit Download Page​
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

GetInvolvedPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const GetInvolvedPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <GetInvolvedPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

GetInvolvedPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GetInvolvedPage

export const GetInvolvedPageQuery = graphql`
  query GetInvolvedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
