/** @format */

import React from "react"

const InfoCard = ({ children, cardImage, cardTitle, cardDesc, cardBtn }) => {
  return (
    // <div className="card">
    <div className="card" style={{ height: "100%" }}>
      <div className="card-image">
        <figure className="image is-1by1">
          <img src={cardImage} alt={"card"} />
        </figure>
      </div>
      <div className="card-content p-3">
        <h2 className="my-4 is-size-3 is-size-4-mobile has-text-weight-bold">
          {cardTitle}
        </h2>
        <p className="subtitle has-text-grey mt-4">{children}</p>
      </div>
      {cardBtn ? (
        <footer class="card-footer p-3 mt-auto">
          <a
            href={cardBtn.link}
            className="button is-primary "
            target={"_blank"}
            rel={"noreferrer"}
          >
            {cardBtn.text}
          </a>
        </footer>
      ) : null}
    </div>
  )
}

export default InfoCard
